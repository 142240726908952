<template>
  <el-card class="component">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" label="入库">
        <el-row>
          <el-col :span="12">
            <el-form label-width="100px" @submit.native.prevent>
              <el-form-item  label="手动添加">
                <el-input v-model="custCode" @keydown.enter.native="addCustCode(custCode)"></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="12" class="text-right">
            <el-button type="warning" @click="clearAll" :disabled="addList.length===0">清空</el-button>
            <el-button type="warning" @click="sort" :disabled="addList.length===0">排序</el-button>
            <el-button v-if="addedList.length > 0" type="warning" @click="addList = addedList" :disabled="addList.length>0">刚才数据</el-button>
            <el-button type="primary" @click="inStore" v-loading="waiting" :disabled="addList.length===0">入库</el-button>
            <!-- <el-button type="warning" v-if="typeNum === 1" :disabled="addList.length<2" @click="updateStore">更新</el-button> -->
          </el-col>
        </el-row>
        <table class="table border">
          <thead>
            <tr>
              <th>#</th>
              <th>操作</th>
              <th>条码</th>
              <th>款号</th>
              <th>款式</th>
              <th>颜色</th>
              <th>图片</th>
              <th>尺码</th>
              <th>关联款号</th>
              <th width="80">数量</th>
              <th width="80">po数量</th>
              <th>备注</th>
            </tr>
          </thead>
          <tbody>
            <tr :title="itemStatus(item)"
              :class="{'is-mul': item.isMul, 'is-store': item.exist,'no-po':(item.po_num < item.count), 'is-export': item.status=='5' }"
              v-for="(item, idx) in addList" :key="idx">
              <td>{{idx + 1}}</td>
              <td><el-button type="danger" @click="delItem(idx)" icon="fa fa-trash"></el-button></td>
              <td>{{item.code}}</td>
              <td>{{item.kh}}</td>
              <td>{{item.sub_category}}</td>
              <td>{{item.color_text}}</td>
              <td><img v-if="item.imgs && item.imgs.length > 0" :src="'data:image/jpg/png/gif;base64,'+item.imgs[0]['simg']" alt=""></td>
              <td>{{item.size_text}}</td>
              <td>{{item.rela_style}}</td>
              <td>
                <el-input v-if="item.isNew" style="width: 100%" type="number" v-model="item.count"></el-input>
                <template v-else>{{item.count}}</template>
              </td>
              <td>
                <span v-if="item.isNew">{{item.po_num}}</span>
              </td>
              <td><el-input v-model="item.remark"></el-input></td>
            </tr>
          </tbody>
        </table>
        <el-dialog :visible.sync="showDialog" title="详情"
          :close-on-click-modal="false" :close="closeShowDialog">
          <el-row :gutter="10">
            <el-col :span="12">
              <div class="grid-content">
                <label>款号：</label>
                {{showObj.kh}}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content">
                <label>款式：</label>
                {{showObj.sub_category}}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content">
                <label>颜色：</label>
                {{showObj.color_text}}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="grid-content">
                <label>尺码：</label>
                {{showObj.size_text}}
              </div>
            </el-col>
          </el-row>
          <div>
            <span class="text-primary" style="font-size: 20px;">{{showObj.code}}</span>
          </div>
          <div>
            <img :src="'data:image/jpg/png/gif;base64,'+showObj.img" alt="">
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="showDialog = false">
              <i class="fa fa-fw fa-times" aria-hidden="true"></i> 关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane name="second" label="入库记录">
        <inLog></inLog>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import InLog from './part/InList';

export default {
  components: {
    InLog,
  },
  data() {
    return {
      activeName: 'first',
      waiting: false,
      custCode: '',
      code: '',
      scanCode: '',
      lastKeyTime: null,
      addList: [],
      addedList: [],
      showDialog: false,
      showObj: {},
    };
  },
  created() {
    window.document.addEventListener('keypress', this.keypress);
  },
  computed: {
    typeNum() {
      let count = 0;
      const map = {};
      this.addList.forEach((item) => {
        const mcode = item.code.substr(0, 10);
        if (!map[mcode]) {
          map[mcode] = 1;
          count++;
        }
      });
      return count;
    },
  },
  methods: {
    keypress(e) {
      const curTime = new Date().getTime();
      const keyCode = e.which;
      if (!this.lastKeyTime) {
        this.lastKeyTime = curTime;
      }
      if ((curTime - this.lastKeyTime) < 40) {
        if (keyCode === 13) {
          this.scanCode = this.code;
          const p1 = /^([A-Z][0-9]{7}.{0,3}[A-Z]{1,2})[0-9]{0,3}$/;
          const p2 = /^[0-9]{13}$/;
          if (p1.test(this.scanCode) || p2.test(this.scanCode)) {
            this.addCode(this.scanCode);
          }
        } else if (keyCode !== 16) {
          this.code += String.fromCharCode(keyCode);
        }
      } else {
        this.code = '';
        if (keyCode !== 16) {
          this.code = String.fromCharCode(keyCode);
        }
      }
      this.lastKeyTime = curTime;
    },
    addCustCode(code) {
      const p1 = /^([A-Z][0-9]{7}.{0,3}[A-Z]{1,2})[0-9]{0,3}$/;
      const p2 = /^[0-9]{13}$/;
      if (p1.test(code) || p2.test(code)) {
        this.addCode(code);
      } else {
        this.$message({
          type: 'error',
          message: `[${code}] 不是正确的条码编号`,
        });
      }
    },
    addCode(code) {
      const p1 = /^([A-Z][0-9]{7}.{0,3}[A-Z]{1,2})[0-9]{0,3}$/;
      const matchObj = code.match(p1);
      let ecode = code;
      if (matchObj) {
        ecode = matchObj[1];
      }
      ecode = encodeURIComponent(ecode);
      this.$http.get(`eclothesbycode/${ecode}`).then((resp) => {
      // this.$http.get('clothesbycode/2131112117').then((resp) => {
        let item = { code };
        if (resp.data.clothes) {
          item = { ...resp.data.clothes, code, count: 1, isNew: true, exist: false };
          item.remark = '';
          if (item.code.length === 13) {
            item.isNew = false;
          }
          // 新旧显示要统一的字段
          if (item.isNew) {
            item.e_clothes_id = item.id;
            item.kh = item.code.substr(0, 5);
            item.size_text = item.size;
            item.color_text = item.color;
          } else {
            item.clothes_id = item.id;
            item.sub_category = item.category_text;
            item.kh = '' + item.season + item.category + item.style_num;
            item.kh = '' + item.kh + '[' + item.rela_style + ']';
          }
        } else {
          this.$message({
            type: 'warning',
            message: '未找到对应款式',
          });
          return;
        }
        // 旧编号
        if (resp.data.stock) {
          item.exist = true;
          item.out_type = resp.data.stock.out_type;
        }
        // this.showDialog = true;
        this.showObj = item;
        if (item.code.length === 13) {
          item.isNew = false;
          this.addList.push(item);
          this.showMultiple();
        } else {
          item.isNew = true;
          this.addNewItem(item);
        }
      }, (err) => {
        this.$utils.resp(err);
      });
    },
    clearAll() {
      if (this.addList.length === 0) {
        return;
      }
      this.$confirm('是否确认清空？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addList = [];
      }, () => {});
    },
    delItem(index) {
      this.$confirm('是否确认删除？', '确认信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.addList.splice(index, 1);
        this.showMultiple();
      }, () => {});
    },
    sort() {
      this.showMultiple();
      this.addList = this.addList.sort((a, b) => {
        let result = 0;
        if (a.code > b.code) {
          result = 1;
        } else {
          result = -1;
        }
        return result;
      });
    },
    // 标记重复编号，旧编号有用
    showMultiple() {
      this.addList.forEach((item) => {
        if (this.addList.filter(x => x.code === item.code).length > 1) {
          this.$set(item, 'isMul', true);
        } else {
          this.$set(item, 'isMul', false);
        }
      });
    },
    // 新编号添加判断是否存在，存在加count
    addNewItem(obj) {
      const arr = this.addList.filter(x => x.code === obj.code);
      if (arr.length > 0) {
        arr[0].count = arr[0].count * 1 + 1;
        arr[0].po_num = obj.po_num;
        if (obj.po_num < arr[0].count) {
          arr[0].noPo = true;
          this.$message({
            type: 'warning',
            message: '入库数量大于订单数量',
            duration: 3000,
            showClose: true,
          });
        } else {
          arr[0].noPo = false;
        }
      } else {
        if (obj.po_num < obj.count) {
          obj.noPo = true;
          this.$message({
            type: 'warning',
            message: '入库数量大于订单数量',
            duration: 3000,
            showClose: true,
          });
        } else {
          obj.noPo = false;
        }
        this.addList.push(obj);
      }
    },
    inStore() {
      // const list = this.addList.map(item => item.code);
      // 有重复的或者库存已经存在的
      const temp = this.addList.filter((item) => {
        let result = false;
        if (item.isMul) {
          result = true;
        }
        if (item.exist) {
          result = true;
        }
        if (item.noPo) {
          result = true;
        }
        return result;
      });
      if (temp.length > 0) {
        this.$message({
          type: 'warning',
          message: '高亮记录不符合入库要求',
          duration: 3000,
          showClose: true,
        });
        return;
      }
      this.waiting = true;
      this.$http.post('e-clothes-ins', { list: this.addList }).then(() => {
        const duration = 3000;
        this.$message({
          type: 'success',
          message: '成功入库',
          duration,
          showClose: true,
        });
        this.waiting = false;
        this.addedList = this.addList;
        this.addList = [];
      }, (resp) => {
        this.waiting = false;
        this.$utils.resp(resp);
      });
    },
    updateStore() {
      const list = this.addList.map(item => item.code);
      this.$http.put('clothes-check', { list }).then((resp) => {
        const result = resp.data;
        this.$message({
          type: 'success',
          message: `更新入库 ${result.update} 条; 新增入库 ${result.new} 条`,
        });
      });
    },
    itemStatus(item) {
      let result = '';
      if (item.isMul) {
        result = '重复的记录';
      }
      if (item.exist) {
        result = '已入过库';
      }
      if (item.status === '5') {
        result = '出库的请在返库重操作';
      }
      return result;
    },
    closeShowDialog() {

    },
    handleClick() {},
  },
  destroyed() {
    window.document.removeEventListener('keypress', this.keypress);
  },
};
</script>

<style scoped>
.table tr.is-mul, .table tr.is-mul.is-export {
  background: #ffce54;
}
.table tr.is-store {
  background: rgb(235, 111, 80);
}
.table tr.is-export {
  background: rgb(227, 247, 118);
}
.table tr.no-po {
  background: rgb(235, 111, 80);
}
</style>
