<template>
  <el-card>
    <div class="clearfix">
      <el-button type="primary" @click="toAdd">新增</el-button>
      <div class="mar-btm-10 pull-right">
        <div class="box-inline standard-search">
          <el-select v-model="queryForm.status" clearable>
            <el-option value="1" label="未完成"></el-option>
            <el-option value="2" label="已完成"></el-option>
          </el-select>
        </div>
        <el-button size="small" @click="onSubmitQuery">
          <i class="fa fa-fw fa-search"></i>
        </el-button>
      </div>
      <file-uploader ref="file"
        accept=".xlsx"
        btnName="上传excel"
        :multiple="false"
        @change="fileChange"
        :showPreview="false"
        :showFileHint="false">
      </file-uploader>
    </div>
    <div>
      <table class="table bordered">
        <thead>
          <tr>
            <th></th>
            <th>订单日期</th>
            <th>交期</th>
            <th>总件数</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in list" :key="item.id">
            <td>{{item.remark}}</td>
            <td>{{item.order_date}}</td>
            <td>{{item.deadline}}</td>
            <td>{{item.total}}</td>
            <td>
              <el-button type="warning" icon="el-icon-edit" @click="toEdit(item)"></el-button>
              <el-button type="danger" icon="el-icon-delete" @click="delIt(item)"></el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <el-dialog :visible.sync="uploadDialog" title="上传订单" :close-on-click-modal="false">
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th v-for="item in uploadSizes" :key="item">{{item}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in uploadList" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code.substr(0, 8)}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td v-for="size in uploadSizes" :key="size">{{item[size]}}</td>
          </tr>
        </tbody>
      </table>
      <el-form model="uploadObj" label-width="100px">
        <el-form-item label="订单日期">
          <el-date-picker v-model="uploadObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="交期">
          <el-date-picker v-model="uploadObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="addDialog" title="新订单" :close-on-click-modal="false">
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>数量</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in addNewArr" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.size}}</td>
            <td><el-input v-model="item.total"></el-input></td>
            <td><el-button type="danger" icon="el-icon-delete" @click="delNewItem(index)"></el-button></td>
          </tr>
          <tr>
            <td colspan="7">
              <label>新增款号：</label>
              <el-autocomplete
                v-model="addItemCode"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <span class="name">{{ item.code }}</span>
                </template>
              </el-autocomplete>
            </td>
          </tr>
        </tbody>
      </table>
      <el-form model="newObj" label-width="100px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="订单日期">
              <el-date-picker v-model="newObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="交期">
              <el-date-picker v-model="newObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="saveNew" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editDialog" title="订单" :close-on-click-modal="false">
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>数量</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in addNewArr" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.size}}</td>
            <td>
              {{item.total_in}}/{{item.orgTotal}}
              <el-input v-model="item.total" style="width: 100px;"></el-input>
              <el-button icon="el-icon-check" :disabled="waiting" type="primary" @click="updateItem(item)"></el-button>
            </td>
            <td><el-button type="danger" :disabled="waiting" icon="el-icon-delete" @click="delEditItem(item, index)"></el-button></td>
          </tr>
          <!-- <tr>
            <td colspan="7">
              <label>新增款号：</label>
              <el-autocomplete
                v-model="addItemCode"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <span class="name">{{ item.code }}</span>
                </template>
              </el-autocomplete>
            </td>
          </tr> -->
        </tbody>
      </table>
      <el-form model="editObj" label-width="100px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="订单日期">
              <el-date-picker v-model="editObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="交期">
              <el-date-picker v-model="editObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <!-- <el-button type="primary" @click="saveNew" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button> -->
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as xlsx from 'xlsx';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      loading: false,
      waiting: false,
      allSize: ['F', 'XS', 'S', 'S/M', 'M', 'M/L', 'L', 'XL', 'XXL'],
      addDialog: false,
      queryForm: {
        status: null,
      },
      addNewArr: [],
      list: [],
      files: [],
      khMaps: null,
      addArr: [],
      uploadSizes: [],
      uploadDialog: false,
      uploadObj: {
        order_date: this.$utils.FormatDate(),
        deadline: null,
      },
      newObj: {
        order_date: this.$utils.FormatDate(),
        deadline: null,
      },
      addItemCode: '',
      editDialog: false,
      editObj: {},
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    uploadList() {
      const r = [];
      this.addArr.forEach((item) => {
        let lastOne = null;
        if (r.length > 0) {
          lastOne = r[r.length - 1];
        }
        if (lastOne && lastOne.code.substr(0, 8) === item.code.substr(0, 8)) {
          lastOne[item.size] = item.total;
          this.$set(lastOne, item.size, item.total);
        } else {
          const obj = {
            code: item.code,
            kh: item.kh,
            color: item.color,
          };
          obj[item.size] = item.total;
          r.push(obj);
        }
      });
      console.log(r);
      return r;
    },
    newExistMap() {
      const map = {};
      this.addNewArr.forEach((i) => {
        map[i.code.substr(0, 8)] = 1;
      });
      return map;
    },
  },
  created() {
    this.getSKUs();
    this.onSubmitQuery();
  },
  methods: {
    getSKUs() {
      this.axios.get('allkhclothes').then((r) => {
        const list = r.data;
        this.khMaps = list;
      });
    },
    fileChange(files) {
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      } else {
        this.files = [];
      }
      if (!this.khMaps) {
        setTimeout(() => {
          this.toAddPo();
        }, 5000);
      } else {
        this.toAddPo();
      }
    },
    toAddPo() {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const wb = xlsx.read(data, { type: 'binary' });
        const sheetName = wb.SheetNames[0];
        const list = xlsx.utils.sheet_to_json(wb.Sheets[sheetName], { range: 0, header: 'A' });
        let row = 0;
        const errs = [];
        const addList = [];
        const addArr = [];
        // const allkh = {};
        let sizeRow = null;
        const colMap = {};
        const sizes = [];
        list.forEach((item) => {
          row++;
          const keys = Object.keys(item);
          if (row < 5) {
            keys.forEach((col) => {
              const itemVal = item[col];
              if (sizeRow && sizeRow === row) {
                if (this.allSize.indexOf(itemVal) > 1) {
                  colMap[itemVal] = col;
                  sizes.push(itemVal);
                }
              }
              if (itemVal === '款号') {
                colMap.kh = col;
              }
              if (itemVal === '颜色') {
                colMap.color = col;
              }
              if (!colMap.sizeCol && itemVal.indexOf('码') !== -1) {
                colMap.sizeCol = col;
                sizeRow = row + 1;
              }
            });
          }
          // 尺码后的行
          if (row > sizeRow) {
            if (item[colMap.kh]) {
              const kh = item[colMap.kh];
              console.log(sizes);
              const color = item[colMap.color];
              if (kh && color) {
                const obj = {
                  kh,
                  color,
                };
                sizes.forEach((size) => {
                  obj[size] = item[colMap[size]];
                });
                // allkh[kh] = 1;
                const colorMaps = this.khMaps[kh];
                if (colorMaps) {
                  const sizeMaps = colorMaps[color];
                  if (sizeMaps) {
                    sizes.forEach((size) => {
                      const sizelist = sizeMaps[size];
                      if (sizelist) {
                        const cloth = sizelist[0];
                        addArr.push({ e_clothes_id: cloth.id, code: cloth.code, kh, color, size, total: obj[size] });
                      } else {
                        errs.push({ row, kh, color, size });
                      }
                    });
                  } else {
                    errs.push({ row, kh, color });
                  }
                } else {
                  errs.push({ row, kh });
                }
                addList.push(obj);
              }
            }
          }
        });
        this.uploadSizes = sizes;
        this.addArr = addArr;
        this.uploadDialog = true;
        // console.log(errs);
        // console.log(addArr);
        // console.log(addList);
      };
      fileReader.readAsBinaryString(this.files[0]);
    },
    cancelUpload() {
      this.uploadDialog = false;
      this.addArr = [];
      this.$refs.file.reset();
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.addNewArr = [];
      this.addDialog = true;
    },
    getList() {
      this.loading = true;
      this.axios.get('e-clothes-po', { params: {} }).then((r) => {
        this.loading = false;
        this.list = r.data.data;
        this.totalItem = r.data.total;
      }, (e) => {
        this.loading = false;
        this.$utils.resp(e);
      });
    },
    save() {
      const obj = {
        ...this.uploadObj,
        list: this.addArr,
      };
      this.waiting = true;
      this.axios.post('e-clothes-po', obj).then(() => {
        this.waiting = false;
        this.getList();
        this.uploadDialog = false;
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    querySearchAsync(q, cb) {
      if (q) {
        const params = {
          code: q,
          page: 1,
          pageSize: 30,
        };
        this.$http.get('e-clothes', { params }).then((r) => {
          cb(r.data.data);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(obj) {
      if (obj.code) {
        const colorCode = obj.code.substr(0, 8);
        if (this.newExistMap[colorCode]) {
          this.$confirm('已经有该款号颜色，是否覆盖?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.addNewKhs(obj);
          }, () => {});
        } else {
          this.addNewKhs(obj);
        }
      }
    },
    addNewKhs(obj) {
      const colorCode = obj.code.substr(0, 8);
      const params = {
        code: colorCode,
        page: 1,
        pageSize: 30,
      };
      this.addNewArr = this.addNewArr.filter((i) => i.code.substr(0, 8) !== colorCode);
      this.addItemCode = obj.code.substr(0, 8);
      this.$http.get('e-clothes', { params }).then((r) => {
        const l = r.data.data;
        this.allSize.forEach((size) => {
          l.forEach((i) => {
            if (i.size === size) {
              this.addNewArr.push({
                e_clothes_id: i.id,
                code: i.code,
                kh: i.code.substr(0, 5),
                color: i.color,
                size: i.size,
                total: 0,
              });
            }
          });
        });
      });
    },
    delNewItem(index) {
      this.addNewArr.splice(index, 1);
    },
    saveNew() {
      const obj = {
        ...this.newObj,
        list: this.addNewArr,
      };
      this.waiting = true;
      this.axios.post('e-clothes-po', obj).then(() => {
        this.waiting = false;
        this.getList();
        this.addDialog = false;
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    toEdit(obj) {
      this.axios.get(`e-clothes-po/${obj.id}`).then((r) => {
        this.editDialog = true;
        const itemList = r.data.items;
        itemList.forEach((i) => {
          i.orgTotal = i.total;
        });
        this.addNewArr = itemList;
        this.editObj = r.data;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    saveUpdate() {

    },
    updateItem(item) {
      this.waiting = true;
      this.axios.put(`e-clothes-po-item/${item.id}`, item).then(() => {
        this.waiting = false;
        item.orgTotal = item.total;
        this.getList();
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    delEditItem(item, index) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.axios.delete(`e-clothes-po-item/${item.id}`).then(() => {
          this.waiting = false;
          this.getList();
          this.addNewArr.splice(index, 1);
        }, (e) => {
          this.waiting = false;
          this.$utils.resp(e);
        });
      }, () => {});
    },
    delIt(item) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.axios.delete(`e-clothes-po/${item.id}`).then(() => {
          this.getList();
        }, (e) => {
          this.$utils.resp(e);
        });
      }, () => {});
    },

  },
};
</script>

<style>

</style>
